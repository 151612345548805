/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        // IMPLEMENT SCROLLMAGIC

        var controller = new ScrollMagic.Controller({
          globalSceneOptions: {
            duration: 1000
          }
        });

        var $links = $(".nav-primary li");
        $links.each(function(i, e) {
          var anchor = $(e).find('a').attr('href');

          if (anchor.length <= 1) { return; }

          new ScrollMagic.Scene({
            triggerElement: anchor
          })
            .setClassToggle(e, 'active')
            .addTo(controller);
        });


        $(".nav-primary ul li a[href^='#']").on('click', function(e) {
          e.preventDefault();

          var hash = this.hash;
          var offset = $(hash).offset().top;

          var $site_header = $('#site-header');
          if ($site_header) {
            offset -= $site_header.outerHeight();
          }

          var pageHeight = Math.max($(document).height(), $(window).height());
          var maxOffset = pageHeight - $(window).height();

          $('html, body').animate({
            scrollTop: Math.min(offset, maxOffset)
          }, 500);

        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
